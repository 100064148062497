import React from 'react'

import { useMobile } from 'hooks/ua'
import HorizonalSection from 'components/HorizonalSection'
import Button from 'components/UI/Button'
import styles from './style.less'

import pcImg from './images/pc.png'
import mobileImg from './images/mobile.png'

export default function Intro1() {
  const isMobile = useMobile()

  return (
    <HorizonalSection
      title="无限可能的边缘计算平台"
      subtitle="通过自研边缘计算核心技术，服务一线互联网公司及云厂商，探索云服务未来模式。"
      extra={<Button type="primary" size={isMobile ? 'default' : 'large'} className={styles.btn} href="/cooperations#apply">立即参与</Button>}
      reversed
    >
      <img src={isMobile ? mobileImg : pcImg} className={styles.img} />
    </HorizonalSection>
  )
}
