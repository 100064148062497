import React from 'react'

import Feature, {
  Group as FeatureGroup,
  Item as FeatureItem,
  Desc as FeatureDesc
} from 'components/Feature'

import icon1 from './images/icon1.png'
import icon2 from './images/icon2.png'
import icon3 from './images/icon3.png'
import icon4 from './images/icon4.png'

export default function Advantage() {
  return (
    <Feature name="advantage" title="核心优势" subtitle="四大专属优势，提供一体化服务" grey>
      <FeatureGroup>
        <FeatureItem
          pos="top-down"
          icon={icon1}
          title="业务多元化"
        >
          <FeatureDesc>多种业务可供选择</FeatureDesc>
        </FeatureItem>
        <FeatureItem
          pos="top-down"
          icon={icon2}
          title="结算及时"
        >
          <FeatureDesc>每月自动打款，合作更省心</FeatureDesc>
        </FeatureItem>
        <FeatureItem
          pos="top-down"
          icon={icon3}
          title="跑量稳定收益高"
        >
          <FeatureDesc>业务稳定，收益丰厚持续</FeatureDesc>
        </FeatureItem>
        <FeatureItem
          pos="top-down"
          icon={icon4}
          title="省心服务"
        >
          <FeatureDesc>专属服务，1V1 答疑解惑</FeatureDesc>
        </FeatureItem>
      </FeatureGroup>
    </Feature>
  )
}
