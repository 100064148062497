import React, { ReactNode, CSSProperties } from 'react'
import classnames from 'classnames'

import styles from './index.less'

export interface HorizonalSectionProps {
  title?: ReactNode
  subtitle?: ReactNode
  extra?: ReactNode
  reversed?: boolean
  style?: CSSProperties
  className?: string
  children?: ReactNode
}

export default function HorizonalSection(props: HorizonalSectionProps) {
  const {
    title, subtitle = null, children, reversed, extra, ...rest
  } = props

  return (
    <div {...rest} className={classnames(styles.wrapper, props.className)}>
      <div className={classnames(styles.intro, reversed && styles.reversed)}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
          {extra}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}
