/**
 * @file 首页内容
 */

import React from 'react'

import Layout from 'components/Layout'
import { headerThemeContext } from 'components/Header/Pc'
import UsageGuide from 'components/pages/index/UsageGuide'
import { Navigatable } from 'components/Navigator'

import Banner from 'components/pages/index/Banner'
import Intro1 from 'components/pages/index/Intro1'
import Advantage from 'components/pages/index/Advantage'

function PageContent() {
  return (
    <Navigatable>
      <Banner />
      <Intro1 />
      <Advantage />
      <UsageGuide />
    </Navigatable>
  )
}

export default function IndexPage() {
  return (
    <headerThemeContext.Provider value="default" >
      <Layout
        title=""
        keywords="NiuLink, PCDN, 边缘计算平台"
        description="NiuLink 简单高效的雾计算平台"
      >
        <PageContent />
      </Layout>
    </headerThemeContext.Provider>
  )
}
