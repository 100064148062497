/**
 * @file 首页页面底部使用引导
 * @description 在用户浏览完页面后再做一次引导
 */

import React from 'react'

import Button from 'components/UI/Button'
import { useMobile } from 'hooks/ua'

import styles from './style.less'

export default function UsageGuide() {
  const isMobile = useMobile()
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>共享闲置资源，获取现金收益</h3>
      <div className={styles.desc}>
        {isMobile ? '加入 NiuLink 成为资源节点，获取可靠现金收益' : '加入 NiuLink 成为资源节点，共享自有闲置网络资源，获取可靠现金收益。'}
      </div>
      <Button type="primary" size={isMobile ? 'default' : 'large'} className={styles.btn} href="/cooperations#apply">立即参与</Button>
    </div>
  )
}
